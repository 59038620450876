import React from "react";
import {IColumn, ITableProps} from "./interface";
import './index.less'
import Pagination from "@/components/Pagination";

const ZTable = (props: ITableProps) => {
    const {
        data = [],
        column = [],
        border = false,
        align = 'left',
        pagination = false,
        total,
        onChange,
        onRowClick,
        vertical = 'middle',
        pageSize,
        rowClass,
        size = 'default',
        nested,
        showEmpty = true
    } = props

    const empty = () => (
        <div className='zc-table-empty color-999'>
            <img src="/images/nodata.jpg" alt=""/>
            <div> 暂无数据</div>
        </div>
    )

    // th
    const initThAttr = (i: IColumn) => {
        return {
            style: {
                width: i.width,
                minWidth: i.width,
                textAlign: align,
                border: !border && "0" || '1px solid rgba(203, 215, 230, 1)',
                verticalAlign: vertical,
                padding: size === 'default' && '14px 1% 14px 1%' || size === 'excel' && '0' || '8px 0'
            }
        }
    }
    // valueRender
    const valueRender = (i: any, j: IColumn, s?: any, nIndex?: number, index?: number) => {
        if (j && j.render) {
            return j.render(i[j.key as string], i, s, nIndex, index)
        } else {
            return i[j.key as string] || ''
        }
    }


    //
    const nestRender = (i: any, index: number) => i[nested]?.map?.((n: any, nIndex: number) =>
        <tr key={nIndex} className={`zc-tr ${rowClass?.(i, index) || ''}`} onClick={() => onRowClick?.(i)}>
            {
                column.map((j: IColumn, subIndex: number) =>
                    (nIndex === 0 || !j.child) && <td rowSpan={j.child && i[nested].length || 1}
                                                      key={subIndex} {...initThAttr(j)}>{valueRender(n, j, i, nIndex, index)}</td>
                )
            }
        </tr>
    )
    //
    const commonRender = (i: any, index: number) =>
        <tr key={index} className={`zc-tr ${rowClass?.(i) || ''}`} onClick={() => onRowClick?.(i)}>
            {
                column.map((j: IColumn, subIndex: number) => <td
                    key={subIndex} {...initThAttr(j)}>{valueRender(i, j, i, index)}</td>)
            }
        </tr>


    return (
        <div style={{width: '100%', overflowX: 'hidden'}}>
            <table className="zc-table">
                <thead>
                <tr>
                    {
                        column.map((i: IColumn, index: number) => <th key={index}  {...initThAttr(i)}>
                            <div className={!border && 'thead-class' || ''}>{i.title}</div>
                        </th>)
                    }
                </tr>
                </thead>
                <tbody>
                {
                    data.map((i: any, index: number) => !!nested && nestRender(i, index) || commonRender(i, index))
                }
                </tbody>
            </table>
            {!data?.length && showEmpty && empty() || ''}
            {pagination && <Pagination total={total} onChange={onChange} pageSize={pageSize}/> || ''}
        </div>
    )
}

export default ZTable